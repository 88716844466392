import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/Layout'
import Overview from '../components/templates/PageTechnology/Overview'

export default function PageGatsbyShipyard(props: PageProps) {
  const {
    title,
    description,
    image,
    overview,
    content
  } = (props.data as any).content.edges[0].node.childShipyardJson

  return (
    <Layout
      title={title}
      description={description}
      uri={props.uri}
      location={props.location}
      heroCenter
      heroNoWaves
      heroStyle={{
        paddingBottom: 'var(--spacer)'
      }}
    >
      <Overview
        overview={overview}
        image={image && image.childImageSharp && image.childImageSharp.fluid}
      />
      <Overview overview={content} />
    </Layout>
  )
}

// From pageQuery @ src/components/templates/PageTechnology/index.tsx
export const contentQuery = graphql`
  query ShipyardQuery {
    content: allFile(
      filter: { relativePath: { eq: "pages/shipyard/shipyard.json" } }
    ) {
      edges {
        node {
          childShipyardJson {
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 506) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            overview {
              text
            }
            content {
              text
            }
          }
        }
      }
    }
  }
`
