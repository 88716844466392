import React from 'react'
import Img, { FluidObject } from 'gatsby-image'
import shortid from 'shortid'
import { markdownify } from '../../../utils'
import styles from './Overview.module.css'

export interface OverviewProps {
  overview: {
    text: string
    list?: string[]
  }
  image?: FluidObject
}

export default function Overview({ overview, image }: OverviewProps) {
  const { text, list } = overview

  return (
    <div className={styles.overview}>
      <div>
        {image && <Img className={styles.image} fluid={image} Tag="figure" />}
        <div className={styles.text}>{markdownify(text)}</div>
      </div>

      {list && (
        <ul className={styles.list}>
          {list.map((item: string) => (
            <li key={shortid.generate()}>{markdownify(item)}</li>
          ))}
        </ul>
      )}
    </div>
  )
}
